<template>
  <div>
    <div class="headerMobile">
      <div class="btn-back" v-if="showbackHeader" @click="handleBack"></div>
      <img class="logo" v-else :src="imgUrl" />

      <img class="tabIcon" :src="gData.tabIcon" @click.stop="tabBtnClick" />
      <div v-show="sidebarShow" class="overlay"></div>
      <transition
        enter-active-class="animated fadeInRight"
        leave-active-class="animated fadeOutRight"
        id="headerMobileAnima"
      >
        <div v-show="sidebarShow" class="sidebar" @click.stop="tabBtnClick">
          <!-- <div class="overlay"></div> -->
          <div class="contentClick"></div>
          <div class="content">
            <img
              class="close"
              :src="gData.tabClose"
              @click.stop="tabBtnClick"
            />
            <div class="btns">
              <div
                class="btnItem"
                v-for="(item, index) in gData.btns"
                :key="index"
                @click="jump(item.url)"
              >
                <div
                  class="txt"
                  v-bind:class="{ chooseBtn: gData.index == index }"
                >
                  {{ item.name }}
                </div>
                <div class="line" v-if="gData.index == index"></div>
              </div>
            </div>
          </div>
        </div>
      </transition>
    </div>
    <div class="entity"></div>
  </div>
</template>

<script>
export default {
  name: "headerMobile",
  props: {
    gData: Object,
  },
  inject: {
    showbackHeader: {
      from: "showbackHeader",
      default: false, // 要给定一个默认值
    },
  },
  data() {
    return {
      sidebarShow: false,
      imgUrl: require("../assets/logo_xmiles_top.svg"),
    };
  },
  methods: {
    tabBtnClick() {
      this.sidebarShow = !this.sidebarShow;
    },
    jump(url) {
      window.location.href = url;
    },
    //跳转开发者登录
    jumpDeveloperLogin() {
      window.location.href = this.gData.developerLogin;
    },
    handleBack() {
      window.history.go(-1);
    },
  },
  mounted() {
    // 将“加入我们”展开 ==》 社会招聘+校园招聘
    this.gData.btns = this.gData.btns.reduce((pre, cur) => {
      pre = cur.children ? pre.concat(cur.children) : pre.concat(cur);
      return pre;
    }, []);
  },
};
</script>

<style lang="scss" scoped>
.entity {
  width: 100%;
  height: 56px;
}
.headerMobile {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 56px;
  background-color: white;
  z-index: 90000;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 20px;
  box-sizing: border-box;
  .logo {
    // margin: 10px 0 0 20px;
    width: 108px;
    height: 20px;
  }

  .btn-back {
    width: 29px;
    height: 18px;
    background-image: url("../assets/icon_back.png");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
  }

  .tabIcon {
    // position: absolute;
    // top : 73px;
    // right : 77px;
    width: 18px;
    height: 18px;
    cursor: pointer;
  }
  .overlay {
    width: 100%;
    height: 100%;
    position: fixed;
    z-index: 99999;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.4);
  }
  .sidebar {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 99999;
    width: 100%;
    height: 100%;
    .contentClick {
      position: absolute;
      top: 0;
      left: 0;
      width: calc(100% - 998px);
      height: 100%;
    }
    .content {
      position: absolute;
      top: 0;
      right: 0;
      width: 260px;
      height: 100%;
      background-color: white;
      .close {
        position: absolute;
        top: 18px;
        right: 18px;
        width: 18px;
        height: 18px;
        cursor: pointer;
      }
      .btns {
        margin-top: 117-40px;
        margin-left: 110px;
        width: auto;
        height: 1230px;
        .btnItem {
          margin: 40px 0;
          // height : 92px;
          .txt {
            display: inline-block;
            width: 80px;
            // height : 92px;
            text-align: right;
            cursor: pointer;
            color: #666666;
            font-weight: 400;
            font-size: 16px;
          }
          .line {
            position: relative;
            display: inline-block;
            width: 2px;
            height: 12px;
            background: #222222;
            border-radius: 2px;
            margin-left: 20px;
            top: 0;
          }
          .chooseBtn {
            color: #222222;
            font-weight: bold;
          }
        }
      }
    }
  }
  #headerMobileAnima {
    animation-duration: 10s;
  }

  .animated {
    animation-duration: 0.75s;
  }
}
</style>